import './App.scss';

import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Home from './Home';
import RSVP from './RSVP';
import Tickets from './Tickets';
import driveInFirebase from './helpers/firebase';

driveInFirebase.signIn();

function App() {
  return (
    <div className='shell'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/get-tickets" element={<RSVP />} />
          <Route path="/thanks" element={<Tickets />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
