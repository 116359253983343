import React, { useEffect } from 'react';
import driveInFirebase from './helpers/firebase';

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

import ford_drive_in_logo from './images/ford_drive_in.svg';
import ford_ticket from './images/Ford_DriveInTicket.png';
import of_the from './images/of_the.svg';
import to from './images/to.svg';

import './Tickets.scss';

function Tickets() {
  // const thisURL = `${window.location.protocol}//${window.location.hostname}`;
  const thisURL = 'https://forddriveintheaters.com';

  useEffect(() => {
    driveInFirebase.signOut();
  }, [])

  return (
    <div className='wrapper'>
      <div className="content">
        <img src={ford_drive_in_logo} className="logo" alt="Ford Drive-In" />
        <div className="double-wide">
          <div className='ticket'>
            <img src={ford_ticket} className="logo" alt="Ford Drive-In Ticket" />
          </div>
          <div className='event-content'>
            <div className='showtime'>
              SHOWTIME
            </div>
            
            <div className='blue-table-tickets'>
              YOUR TICKETS <img src={to} alt="to" className='and-tickets' />
            </div>

            <div className='blue-table-tickets'>
              EDGE <img src={of_the} alt="of the" className='and-home' /> UNKNOWN
            </div>

            <div className='blue-table-tickets'>
              WITH JIMMY CHIN
            </div>

            <div className='blue-table-tickets'>
              HAVE BEEN EMAILED
            </div>

            <div className='blue-table-tickets-arrow'>
              <div className='arrow-wrapper'>
                <span className='arrow-right'></span>
              </div>
              <div>
                TO YOU
              </div>
              <div className='arrow-wrapper'>
                <span className='arrow-left'></span>
              </div>
            </div>

            <div className='do-share'>
              SHARE ON SOCIAL
            </div>

            <div className='social-buttons'>
              <FacebookShareButton url={thisURL}>
                <FacebookIcon className='social-button' />
              </FacebookShareButton>
              <TwitterShareButton url={thisURL}>
                <TwitterIcon className='social-button' />
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tickets;
