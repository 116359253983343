// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getFirestore, doc, getDoc, addDoc, collection } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBoTuccGDc25llWCZjVbJNYXob71KP1epk",
    authDomain: "ford-drive-in.firebaseapp.com",
    projectId: "ford-drive-in",
    storageBucket: "ford-drive-in.appspot.com",
    messagingSenderId: "739888433432",
    appId: "1:739888433432:web:5c354d185b4710ee73766d"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const firebase = {

    signIn : () => {
        return signInAnonymously(auth);
    },
    
    signOut : () => {
        if (auth.currentUser) {
            return auth.signOut();
        }
    
        return;
    },

    getCount : async () => {
        return await getDoc(doc(db,'/counts/edgeofunknown')).then(counts => {
            const thisCount = counts.data();
            return thisCount!.totalSignups;
        });
    },

    saveTicket : async (data: any) => {
        return addDoc(collection(db, 'signups3'), data)
    },
}

export default firebase;